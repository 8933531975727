/** @jsx jsx */

import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import React, { useState, useEffect } from 'react'

import SEO from '../components/Seo'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { css, jsx } from '@emotion/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { colors, variables } from '../styles/global'

export const Portfolio = ({ data }: any) => {
  const portfolio = data.allDatoCmsPortfolioSerie.edges.map((item: any) => {
    return item.node
  })

  const sortedPortfolio = portfolio.sort(
    (itemA: any, itemB: any) => itemB.ranking - itemA.ranking
  )

  return (
    <Layout>
      <SEO title="Beeld" />
      <ResponsiveMasonry columsCountBreakPoints={{ 300: 1, 750: 2, 900: 3 }}>
        <Masonry gutter={'10px'}>
          {sortedPortfolio.map((item: any, index: number) => {
            return (
              <Link
                to={`/portfolio/${item.titel.replace(/\W/g, '')}`}
                key={index + 'img'}
              >
                <div
                  css={css`
                    position: relative;
                    cursor: pointer;
                    &:hover .portfolioOverlay {
                      opacity: 1;
                    }

                    overflow: hidden;
                  `}
                >
                  <div
                    css={css`
                      transition: transform 1s ease-out;
                      transform: scale(1);
                      &:hover {
                        transform: scale(1.02);
                      }
                    `}
                  >
                    <GatsbyImage
                      className="portfolioImg"
                      image={item.hoofdafbeelding.gatsbyImageData}
                      alt=""
                    />
                  </div>
                  <div className="portfolioOverlay">
                    <div
                      css={css`
                        padding: 20px 0;
                      `}
                    >
                      {item.titel}
                    </div>
                  </div>
                </div>
                <div
                  css={css`
                    display: none;
                    ${variables.mobile} {
                      display: block;
                    }
                    text-align: center;
                    font-size: 0.8rem;
                    margin-bottom: 1rem;
                  `}
                >
                  {item.titel}
                </div>
              </Link>
            )
          })}
        </Masonry>
      </ResponsiveMasonry>
    </Layout>
  )
}

export const query = graphql`
  query portfolioQuery {
    allDatoCmsPortfolioSerie {
      edges {
        node {
          hoofdafbeelding {
            gatsbyImageData(width: 1000)
          }
          titel
          ranking
          beschrijving {
            value
          }
        }
      }
    }
  }
`

export default Portfolio
